<template>
  <!-- 面经插件 与公司复用 -->
  <div class="cson-container">
    <div class="company-warp" ref="companyWrap">
      <div class="clearfix">
        <div class="ms-content">
          <el-tabs v-model="activeTab" class="exp-tabs inner-tabs">
            <el-tab-pane label="面经" name="experience">
              <div class="filterTab">
                <el-autocomplete
                  v-model="searchCompanyKey1"
                  :fetch-suggestions="
                    (queryString, cb) =>
                      querySearch(queryString, cb, 'interview')
                  "
                  :trigger-on-focus="false"
                  placeholder="输入公司名"
                  :popper-append-to-body="false"
                  @input="searchLodash"
                  @select="(option) => selectCompany(option, 'interview')"
                  @blur="blurEvents('interview')"
                  style="margin: 0 10px; width: 200px"
                  @keydown.native.enter="selectDefaultCompany('interview')"
                >
                  <template slot-scope="{ item }">
                    {{ item.name }}
                  </template>
                </el-autocomplete>
                <el-dropdown
                  @command="handleJobs"
                  placement="bottom"
                  trigger="hover"
                >
                  <span class="el-dropdown-link">
                    {{ jobTxt == "" ? "岗位" : jobTxt
                    }}<i class="el-icon-caret-bottom"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="beforeHandleCommand('', '')"
                      >全部</el-dropdown-item
                    >

                    <el-dropdown-item
                      :command="beforeHandleCommand('码农SDE', 0)"
                      >码农SDE</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('前端FrontEng', 1)"
                      >前端FrontEng</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('移动端MobileEng', 2)"
                      >移动端MobileEng</el-dropdown-item
                    >

                    <el-dropdown-item
                      :command="
                        beforeHandleCommand('机器学习MachineLearningEng', 3)
                      "
                      >机器学习MachineLearningEng</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('数据科学DataScience', 4)"
                      >数据科学DataScience</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('产品PM', 5)"
                      >产品PM</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('管理Manager', 6)"
                      >管理Manager</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown
                  @command="handleTarget"
                  placement="bottom"
                  trigger="hover"
                >
                  <span class="el-dropdown-link">
                    {{ targetTxt == "" ? "求职目标" : targetTxt
                    }}<i class="el-icon-caret-bottom"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="beforeHandleCommand('', '')"
                      >全部</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('实习 Internship', 0)"
                      >实习 Internship</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('应届生 New Grad', 1)"
                      >应届生 New Grad</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('在职跳槽', 2)"
                      >在职跳槽</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('合同工', 3)"
                      >合同工</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown
                  @command="handleExpType"
                  placement="bottom"
                  trigger="hover"
                >
                  <span class="el-dropdown-link">
                    {{ expTypeTxt == "" ? "面试类别" : expTypeTxt
                    }}<i class="el-icon-caret-bottom"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="beforeHandleCommand('', '')"
                      >全部</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('HR面试', 0)"
                      >HR面试</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('在线笔试', 1)"
                      >在线笔试</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('电话面试', 2)"
                      >电话面试</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('视频面试', 3)"
                      >视频面试</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('现场面试', 4)"
                      >现场面试</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('校园招聘会', 5)"
                      >校园招聘会</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown
                  @command="handleWorkExp"
                  placement="bottom"
                  trigger="hover"
                >
                  <span class="el-dropdown-link">
                    {{ workExpTxt == "" ? "工作经验" : workExpTxt
                    }}<i class="el-icon-caret-bottom"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="beforeHandleCommand('', '')"
                      >全部</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('在校生 无实习或全职', 0)"
                      >在校生 无实习或全职</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('实习或全职<3个月', 1)"
                      >实习或全职&lt;3个月</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('3个月-1年', 2)"
                      >3个月-1年</el-dropdown-item
                    >
                    <el-dropdown-item :command="beforeHandleCommand('1-3年', 3)"
                      >1-3年</el-dropdown-item
                    >
                    <el-dropdown-item :command="beforeHandleCommand('3-5年', 4)"
                      >3-5年</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('5-10年', 5)"
                      >5-10年</el-dropdown-item
                    >
                    <el-dropdown-item :command="beforeHandleCommand('>10年', 6)"
                      >&gt;10年</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="ms-cells">
                <div
                  class="ms-cell-interview"
                  v-for="(exp, index) in expArray"
                  :key="index"
                >
                  <div
                    @click="viewExps(exp.id, exp.postsId)"
                    style="cursor: pointer"
                  >
                    <div class="ms-cell-hd">
                      <div class="ms-type" style="max-width: 70%">
                        <span
                          v-for="(label, lIndex) in exp.labels"
                          :key="lIndex"
                          :class="[
                            lIndex == 0
                              ? 'orange'
                              : lIndex == 1
                              ? 'red'
                              : lIndex == 2
                              ? 'green'
                              : lIndex == 3
                              ? 'blue'
                              : '',
                          ]"
                        >
                          {{ label }}
                          <span style="padding: 0 5px">{{
                            lIndex == exp.labels.length - 1 ? "" : "|"
                          }}</span></span
                        >
                      </div>
                      <div class="ms-user">
                        <div class="ms-user-avatar-s" v-if="exp.avatar">
                          <router-link :to="`/userPage?userId=${exp.userId}`">
                            <img :src="exp.avatar" />
                          </router-link>
                        </div>
                        <div class="ms-user-avatar-s" v-else>
                          <i class="el-icon-user-solid"></i>
                        </div>
                        <div class="ms-user-name" v-if="exp.username">
                          {{ exp.username }}
                        </div>
                        <div class="ms-user-name" v-else>匿名用户</div>
                        <div class="ms-user-time">
                          发表于{{ exp.createDate }}
                        </div>
                      </div>
                    </div>
                    <div class="ms-cell-tt">
                      {{ exp.title
                      }}<el-tag size="mini">{{ exp.questions }}题</el-tag>
                    </div>
                    <div class="ms-cell-ft">
                      <div class="ms-cell-desc">
                        {{ exp.interviewAfter }}
                      </div>
                      <div class="ms-cell-data">
                        <el-button type="text" size="mini"
                          ><span
                            class="iconfont icon-dianzan"
                            style="margin-right: 4px"
                          ></span
                          >{{ exp.likeNums }}</el-button
                        >
                        <el-button type="text" size="mini"
                          ><span
                            class="iconfont icon-huifu"
                            style="margin-right: 6px"
                          ></span
                          >{{ exp.commentNums }}</el-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pagination-block">
                  <el-pagination
                    v-if="expTotal > 0"
                    background
                    prev-text="< 上一页"
                    next-text="下一页 >"
                    layout="prev, pager, next "
                    :total="expTotal"
                    :current-page.sync="expCurrent"
                    @current-change="(page) => getExp(page, true)"
                    :page-size="20"
                  >
                  </el-pagination>
                </div>
                <div
                  v-if="expTotal == 0"
                  style="text-align: center; font-size: 20px; padding: 30px 0"
                >
                  暂无面经
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="面经题目" name="exams"
              ><div class="filterTab">
                <el-autocomplete
                  style="margin: 0 10px; width: 200px"
                  v-model="searchCompanyKey2"
                  :fetch-suggestions="
                    (queryString, cb) =>
                      querySearch(queryString, cb, 'question')
                  "
                  :trigger-on-focus="false"
                  placeholder="输入公司名"
                  :popper-append-to-body="false"
                  @input="searchLodash"
                  @select="(option) => selectCompany(option, 'question')"
                  @blur="blurEvents('question')"
                  @keydown.native.enter="selectDefaultCompany('question')"
                >
                  <template slot-scope="{ item }">
                    {{ item.name }}
                  </template>
                </el-autocomplete>
                <el-dropdown
                  @command="handleExplain"
                  placement="bottom"
                  trigger="hover"
                >
                  <span class="el-dropdown-link">
                    {{ explainTxt == "" ? "未解答" : explainTxt
                    }}<i class="el-icon-caret-bottom"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="beforeHandleCommand('', '')"
                      >全部</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('未解答', false)"
                      >未解答</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommand('已解答', true)"
                      >已解答</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="ms-cells">
                <div
                  class="ms-cell-interview"
                  v-for="(question, index) in questionArray"
                  :key="index"
                >
                  <div
                    @click.stop="viewQuestions(question.id, question.postsId)"
                    style="cursor: pointer"
                  >
                    <div
                      class="answers"
                      style="color: #fa6400; margin-bottom: 12px"
                    >
                      <img
                        style="
                          width: 16px;
                          height: 16px;
                          border-radius: 50%;
                          vertical-align: top;
                        "
                        :src="question.avatar"
                        alt=""
                        v-if="question.avatar && question.avatar != ''"
                      />
                      <i
                        class="el-icon-user-solid"
                        style="color: #333"
                        v-else
                      />
                      <span
                        style="
                          color: #0075f6;
                          font-size: 12px;
                          text-decoration: underline;
                          padding: 0 5px;
                          vertical-align: top;
                        "
                        v-if="question.username && question.username != ''"
                        >{{ question.username }}</span
                      >
                      <span
                        style="
                          color: #0075f6;
                          font-size: 12px;
                          text-decoration: underline;
                          padding: 0 5px;
                          vertical-align: top;
                        "
                        v-else
                        >匿名用户</span
                      >
                      <span
                        style="
                          font-size: 12px;
                          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                          font-weight: 400;
                          color: rgba(0, 0, 0, 0.5);
                          line-height: 18px;
                          vertical-align: top;
                          margin-right: 16px;
                        "
                        >{{ question.createDate }}</span
                      >
                      <span style="vertical-align: top; margin-right: 12px">{{
                        question.companyName
                      }}</span>

                      <span
                        style="
                          color: #34c759;
                          background: rgba(52, 199, 89, 0.1);
                          border-radius: 1px;
                          padding: 3px 6px;
                          vertical-align: top;
                        "
                        >{{ question.isExplain ? "已解答" : "未解答" }}</span
                      >
                      <div style="float: right">
                        <span
                          style="margin-right: 8px; color: #828282"
                          v-if="
                            question.interviewExperienceId &&
                            question.interviewExperienceId != ''
                          "
                          @click.stop="jumpTips(question.interviewExperienceId)"
                          >查看原帖</span
                        >
                        <span
                          style="color: #0a7aff"
                          v-if="!question.isExplain"
                          @click.stop="alsoAsk(question.id, 1)"
                          >我想问({{ question.sameTopic }})</span
                        >
                      </div>
                      <div
                        style="
                          background: rgba(136, 148, 171, 0.1);
                          border-radius: 2px;
                          padding: 2px 6px;
                          font-size: 14px;
                          margin-top: 12px;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            width: 60%;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                          "
                        >
                          {{ question.interviewQuestions }}
                        </div>
                        <div class="ms-cell-data" style="float: right">
                          <el-button type="text" size="mini"
                            ><span
                              class="iconfont icon-dianzan"
                              style="margin-right: 4px"
                            ></span
                            >{{ question.likeNums }}</el-button
                          >
                          <el-button type="text" size="mini"
                            ><span
                              class="iconfont icon-huifu"
                              style="margin-right: 4px"
                            ></span
                            >{{ question.commentNums }}</el-button
                          >
                          <el-button
                            type="text"
                            size="mini"
                            style="color: #fa6400"
                            @click.stop="
                              answerVisible = true;
                              questionId = question.id;
                            "
                            ><span
                              class="iconfont icon-huifu"
                              style="color: #fa6400; margin-right: 6px"
                            ></span
                            ><span>我要答题</span>
                          </el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pagination-block">
                  <el-pagination
                    v-if="questionTotal > 0"
                    background
                    prev-text="< 上一页"
                    next-text="下一页 >"
                    layout="prev, pager, next "
                    :total="questionTotal"
                    :current-page.sync="questionCurrent"
                    @current-change="(page) => getQuestion(page, true)"
                    :page-size="20"
                  >
                  </el-pagination>
                </div>
                <div
                  v-if="questionTotal == 0"
                  style="text-align: center; font-size: 20px; padding: 30px 0"
                >
                  暂无面经题
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="ms-aside">
          <div class="ms-release" @click="handClick">
            <el-button type="text" icon="el-icon-edit-outline"
              >发面经</el-button
            >
          </div>
          <div class="ms-release" @click="askQuestions">
            <el-button type="text" icon="el-icon-edit-outline"
              >问面经题目</el-button
            >
          </div>
          <SideAdvertisement />
        </div>
        <createQuestion
          :visible="questionVisible"
          :type="type"
          @changeCreateQuestionVisible="(val) => (questionVisible = val)"
          @updateQuestionList="updateQuestionList"
        ></createQuestion>
        <answerQuestion
          :visible="answerVisible"
          @changeAnswerVisible="(val) => (answerVisible = val)"
          :questionId="questionId"
          @updateQuestionList="updateQuestionList"
        ></answerQuestion>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getExp,
  getExpQuestions,
  browse,
  searchCompanys,
  alsoAsk,
} from "@/service/company";
import createQuestion from "./createQuestion";
import answerQuestion from "./answerQuestion";
import SideAdvertisement from "@/components/sideAdvertisement";
import _ from "lodash";
import { mapState } from "vuex";
export default {
  name: "interviewExp",
  props: {
    type: {
      type: String,
      default: "all",
    },
    companyId: {
      type: String,
      default: "",
    },
  },
  components: { createQuestion, answerQuestion, SideAdvertisement },
  data() {
    return {
      activeTab: "experience",
      questionVisible: false,
      interviewExpData: {
        companyId: "",
        post: "",
        target: "",
        interviewExperienceType: "",
        workExperience: "",
      },
      questionExpData: {
        companyId: "",
        isExplain: false,
      },
      companyTxt: "",
      companyTxt2: "",
      jobTxt: "",
      targetTxt: "",
      expTypeTxt: "",
      workExpTxt: "",
      explainTxt: "",
      form: {
        anonymity: false,
        companyId: "",
        interviewQuestions: "",
      },
      expTotal: 0,
      questionTotal: 0,
      expCurrent: 1,
      questionCurrent: 1,
      expArray: [],
      questionArray: [],
      companys1: [],
      companys2: [],
      searchCompanyKey1: "",
      searchCompanyKey2: "",
      timeout: null,
      answerVisible: false,
      questionId: "",
    };
  },
  methods: {
    querySearch(queryString, cb, type) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(
          queryString == ""
            ? []
            : type == "interview"
            ? this.companys1
            : this.companys2
        );
      }, 1000);
    },
    searchLodash: _.debounce(function () {
      this.searchCompanys();
    }, 1000),
    searchCompanys() {
      if (this.activeTab == "experience" && this.searchCompanyKey1 == "") {
        this.interviewExpData.companyId = "";
        this.companyTxt = "";
      } else if (this.activeTab == "exams" && this.searchCompanyKey2 == "") {
        this.questionExpData.companyId = "";
        this.companyTxt2 = "";
      }
      searchCompanys(
        this.activeTab == "experience"
          ? this.searchCompanyKey1
          : this.searchCompanyKey2
      ).then((res) => {
        if (res.success) {
          if (this.activeTab == "experience") {
            this.companys1 = res.result;
          } else {
            this.companys2 = res.result;
          }
        }
      });
    },
    selectCompany(option, type) {
      if (type == "interview") {
        this.searchCompanyKey1 = option.name;
        this.companyTxt = option.name;
        this.interviewExpData.companyId = option.id;
      } else {
        this.searchCompanyKey2 = option.name;
        this.companyTxt2 = option.name;
        this.questionExpData.companyId = option.id;
      }
    },
    selectDefaultCompany(type) {
      if (type == "interview") {
        if (this.companys1.length > 0) {
          this.searchCompanyKey1 = this.companys1[0].name;
          this.companyTxt = this.companys1[0].name;
          this.interviewExpData.companyId = this.companys1[0].id;
        }
      } else {
        if (this.companys2.length > 0) {
          this.searchCompanyKey2 = this.companys2[0].name;
          this.companyTxt2 = this.companys2[0].name;
          this.questionExpData.companyId = this.companys2[0].id;
        }
      }
    },
    blurEvents(type) {
      if (type == "interview") {
        if (this.companys1.length == 0) {
          this.searchCompanyKey1 = "";
          this.interviewExpData.companyId = "";
        } else {
          this.searchCompanyKey1 = this.companys1[0].name;
          this.interviewExpData.companyId = this.companys1[0].id;
        }
      } else {
        if (this.companys2.length == 0) {
          this.searchCompanyKey2 = "";
          this.questionExpData.companyId = "";
        } else {
          this.searchCompanyKey2 = this.companys2[0].name;
          this.questionExpData.companyId = this.companys2[0].id;
        }
      }
    },
    handClick() {
      window.open(
        `#/interviewEdit${
          this.type == "all" ? "" : "?companyId=" + this.$route.query.id
        }`
      );
    },
    beforeHandleCommand(txt, command) {
      return {
        txt: txt,
        command: command,
      };
    },
    handleCompany(command) {
      this.interviewExpData.companyId = command.command;
      this.companyTxt = command.txt;
    },
    handleCompany2(command) {
      this.questionExpData.companyId = command.command;
      this.companyTxt2 = command.txt;
    },
    handleJobs(command) {
      console.log(command);
      this.interviewExpData.post = command.command;
      this.jobTxt = command.txt;
    },
    handleTarget(command) {
      this.interviewExpData.target = command.command;
      this.targetTxt = command.txt;
    },
    handleExpType(command) {
      this.interviewExpData.interviewExperienceType = command.command;
      this.expTypeTxt = command.txt;
    },
    handleWorkExp(command) {
      this.interviewExpData.workExperience = command.command;
      this.workExpTxt = command.txt;
    },
    handleExplain(command) {
      this.questionExpData.isExplain = command.command;
      this.explainTxt = command.txt;
    },
    askQuestions() {
      console.log(this.$router);
      if (!this.userInfo && !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      this.questionVisible = true;
    },
    getExp(page, mark) {
      getExp(
        this.type == "all" ? "" : this.$route.query.id,
        String(this.interviewExpData.interviewExperienceType),
        String(this.interviewExpData.post),
        String(this.interviewExpData.target),
        String(this.interviewExpData.workExperience),
        String(page ? page : this.expCurrent)
      ).then((res) => {
        console.log(res);
        if (res.success) {
          this.expTotal = res.result.total;
          this.expArray = res.result.records;
          if (mark) {
            this.$refs.companyWrap.scrollIntoView();
          }
        }
      });
    },
    getQuestion(page, mark) {
      getExpQuestions(
        this.type == "all" ? "" : this.$route.query.id,
        String(this.questionExpData.isExplain),
        String(page ? page : this.questionCurrent)
      ).then((res) => {
        console.log(res);
        if (res.success) {
          this.questionTotal = res.result.total;
          this.questionArray = res.result.records;
          if (mark) {
            this.$refs.companyWrap.scrollIntoView();
          }
        }
      });
    },
    updateQuestionList() {
      this.questionExpData.isExplain = false;
      getExpQuestions(
        this.type == "all" ? "" : this.$route.query.id,
        false,
        this.questionCurrent
      ).then((res) => {
        if (res.success) {
          this.questionTotal = res.result.total;
          this.questionArray = res.result.records;
        }
      });
    },
    viewExps(id, postsId) {
      const data = { superId: id, superType: 1 };
      browse(postsId).then((res) => {
        if (res.success) {
          this.$router.push("/interviewDetail?id=" + id);
        }
      });
    },
    viewQuestions(id, postsId) {
      const data = { superId: id, superType: 5 };
      browse(postsId).then((res) => {
        if (res.success) {
          this.$router.push("/questionDetail?id=" + id);
        }
      });
    },
    alsoAsk(id, type) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      alsoAsk(id, type).then((res) => {
        if (res.success) {
          this.$message.success("提问成功！");
          getExpQuestions(
            this.type == "all" ? "" : this.$route.query.id,
            false,
            this.questionCurrent
          ).then((res) => {
            if (res.success) {
              this.questionTotal = res.result.total;
              this.questionArray = res.result.records;
            }
          });
        } else {
          this.$message.info("您已提过问");
        }
      });
    },
    jumpTips(id) {
      this.$router.push(`/interviewDetail?id=${id}`);
    },
  },
  mounted() {
    this.getExp();
    this.getQuestion();
  },
  watch: {
    interviewExpData: {
      deep: true,
      handler(val) {
        getExp(
          this.type == "all"
            ? this.interviewExpData.companyId
            : this.$route.query.id,
          String(val.interviewExperienceType),
          String(val.post),
          String(val.target),
          String(val.workExperience),
          1
        ).then((res) => {
          console.log(res);
          if (res.success) {
            this.expTotal = res.result.total;
            this.expArray = res.result.records;
            this.expCurrent = 1;
          }
        });
      },
    },
    questionExpData: {
      deep: true,
      handler(val) {
        getExpQuestions(
          this.type == "all"
            ? this.questionExpData.companyId
            : this.$route.query.id,
          String(val.isExplain),
          1
        ).then((res) => {
          if (res.success) {
            this.questionTotal = res.result.total;
            this.questionArray = res.result.records;
            this.questionCurrent = 1;
          }
        });
      },
    },
    $route() {
      window.scrollTo(0, 0);
      this.getExp();
      this.getQuestion();
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },
};
</script>
<style scoped>
@import url("./interviewHome.css");
::v-deep .el-tabs__header {
  line-height: 48px;
  background-color: #fff !important;
  color: #34495e;
  font-size: 16px;
  margin-bottom: 12px;
}
::v-deep .el-tabs__nav-scroll {
  padding: 0 12px;
}
::v-deep .el-tabs__nav-wrap::after {
  background-color: transparent;
}
::v-deep .el-tabs__active-bar {
  background-color: #fa6400;
  border-radius: 2px;
  height: 4px;
  min-width: 28px;
}
::v-deep .el-tabs__item:hover,
::v-deep .el-tabs__item.is-active {
  color: #34495e;
}
.exp-tabs.inner-tabs ::v-deep .el-tabs__item {
  padding: 0 20px !important;
  height: 48px !important;
  box-sizing: border-box !important;
  line-height: 48px !important;
  display: inline-block !important;
  list-style: none !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #34495e !important;
  position: relative !important;
  flex: none !important;
  margin-right: 0 !important;
}
.exp-tabs.inner-tabs ::v-deep .el-tabs__item.is-active {
  border-color: transparent !important;
  background-color: transparent !important;
}

.exp-tabs.inner-tabs ::v-deep .el-tabs__nav {
  float: left !important;
  display: block !important;
}
.filterTab {
  padding: 16px 24px;
  background: #fff;
  margin-bottom: 8px;
}
.filterTab .el-dropdown {
  margin: 0 6px;
  margin-right: 24px;
}
::v-deep .el-dialog__body {
  padding-bottom: 0;
}
.aside-course:hover {
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
}
.ms-release {
  cursor: pointer;
}
.ms-release:hover {
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
}
</style>