<template>
  <!-- 刷题笔记 -->
  <el-dialog
    :visible.sync="dialogVisible"
    width="650px"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="dialog-head" slot="title">答题</div>
    <div class="editor">
      <el-input
        @focus="handleFocus"
        v-show="!showEditor"
        placeholder="写下你的答案..."
      ></el-input>
      <div id="myWangEditor" v-show="showEditor"></div>
    </div>
    <div slot="footer" class="dialog-foot">
      <el-button
        type="info"
        size="mini"
        class="btn text"
        @click="dialogVisible = false"
        >取 消
      </el-button>
      <el-button
        type="warning"
        size="mini"
        class="btn warn"
        @click="submitAnswer"
        >提 交
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { answerQuestion } from "@/service/company";
export default {
  name: "answerQuestion",
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },
    questionId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      myWangEditor: null,
      content: "",
      showEditor: false,
    };
  },
  methods: {
    // initEditor(domId, value, callback) {
    //   const editor = new E(domId);
    //   // 配置 onchange 回调函数
    //   editor.config.onchange = (newHtml) => {
    //     if (value === "content") {
    //       this.content = newHtml;
    //     }
    //   };

    //   // 配置菜单栏，设置不需要的菜单
    //   editor.config.excludeMenus = [
    //     "fontName",
    //     "indent",
    //     "backColor",
    //     "lineHeight",
    //     "link",
    //     "todo",
    //     "justify",
    //     "emoticon",
    //     "image",
    //     "video",
    //     "table",
    //   ];
    //   editor.config.onchangeTimeout = 500;
    //   editor.config.height = 200;
    //   editor.config.showFullScreen = false;
    //   editor.config.showLinkImg = false
    //   editor.create();
    //   if (value === "content") {
    //     editor.txt.html(this.content);
    //     this.myWangEditor = editor;
    //   }
    // },
    handleFocus() {
      this.showEditor = true;
      if (this.myWangEditor) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.myWangEditor.$textElem.elems[0].focus();
            this.myWangEditor.isFocus = true;
          }, 500);
        });
      }
    },
    submitAnswer() {
      answerQuestion({
        content: this.content,
        interviewExperienceDetailId: this.questionId,
      }).then((res) => {
        if (res.success) {
          this.$message.success("回答完成，请等待审核");
          this.dialogVisible = false;
          this.$emit("updateQuestionList");
        }
      });
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("changeAnswerVisible", val);
      },
    },
  },
  mounted() {},
  watch: {
    dialogVisible(val) {
      if (val) {
        this.$nextTick(() => {
          // this.initEditor("#myWangEditor", "content");
        });
      } else {
        this.showEditor = false;
        this.content = "";
        // if (this.myWangEditor) {
        //   this.myWangEditor.destroy();
        // }
      }
    },
  },
};
</script>
<style scoped>
.dialog-head {
  padding: 15px 12px;
  border-bottom: 1px solid rgba(235, 236, 241, 0.91);
  font-size: 18px;
  color: #34495e;
  position: relative;
}

.dialog-head::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 18px;
  background: #fa6400;
  border-radius: 2px;
}
::v-deep .el-dialog__header {
  padding: 0 20px;
}
.dialog-foot {
  text-align: center;
}

.dialog-foot .btn {
  min-width: 88px;
}
</style>